import { Route } from '@angular/router';
import { NavigationComponent } from '@lib/frontend-school-navigation-feature-navigation';
import {
    createHasCompletedOnboardingGuard,
    createIsLoggedInAndActiveGuard,
    createNotLoggedInOrActiveGuard,
} from '@lib/frontend-shared-accounts-data-accounts-service';
import { NamedMediaLibrary } from '@lib/shared-interface-media';
import { PROGRAM_CONTENT_PAGE_ROUTES } from '@lib/frontend-shared-feature-programs-content';
import { createRequiredFeatureGuard } from '@lib/frontend-shared-data-feature-access-service';
import { FeatureFlag } from '@lib/shared-interface-feature-flag';
import { RegistrationWrapperComponent } from '@lib/frontend-school-navigation-feature-registration-wrapper';
import { createRequiredPlanGuard } from '@lib/frontend-shared-data-permissions-service';
import { SchoolAccountPlan } from '@lib/shared-interface-account';
import { environment } from '../environments/environment';

const isLoggedIn = createIsLoggedInAndActiveGuard('/account/login');
const notLoggedIn = createNotLoggedInOrActiveGuard('/dashboard');

const isFullAccess = createRequiredPlanGuard(SchoolAccountPlan.FULL_ACCESS);

const myPlannerEnabled = createRequiredFeatureGuard(FeatureFlag.MY_PLANNER);

export const APP_ROUTES: Route[] = [
    {
        path: '',
        component: NavigationComponent,
        canActivate: [isLoggedIn, createHasCompletedOnboardingGuard('/account/onboarding')],
        children: [
            {
                path: 'account/profile',
                title: 'Edit Profile',
                data: { showEmailChangeWarning: true },
                loadComponent: () =>
                    import('@lib/frontend-shared-accounts-feature-edit-profile-page'),
            },
            {
                path: 'account/billing',
                title: 'Billing',
                loadComponent: () => import('@lib/frontend-school-accounts-feature-billing-page'),
            },
            {
                path: 'accounts/staff',
                title: 'Staff',
                canMatch: [isFullAccess],
                loadComponent: () =>
                    import('@lib/frontend-school-accounts-feature-staff-list-page'),
            },
            {
                path: 'calendar-of-events',
                title: 'Calendar of Events',
                loadComponent: () => import('@lib/frontend-school-feature-calendar-of-events-page'),
            },
            {
                path: 'classes',
                title: 'My Classes',
                loadComponent: () =>
                    import('@lib/frontend-school-school-class-feature-school-class-list'),
            },
            {
                path: 'classes/create',
                title: 'Create Class',
                loadComponent: () =>
                    import('@lib/frontend-school-school-class-feature-create-school-class'),
            },
            {
                path: 'classes/:schoolClassId',
                title: 'Class',
                loadComponent: () => import('@lib/frontend-school-feature-school-class-page'),
                data: {
                    edit: true,
                },
            },
            {
                path: 'classes/:schoolClassId/edit',
                title: 'Edit Class',
                loadComponent: () =>
                    import('@lib/frontend-school-school-class-feature-edit-school-class'),
            },
            {
                path: 'classes/:schoolClassId/passwords',
                title: 'Student Passwords',
                loadComponent: () =>
                    import('@lib/frontend-school-school-class-feature-student-passwords-page'),
                data: {
                    studentPortalOrigin: environment.studentApplication.domain,
                },
            },
            {
                path: 'classes/:schoolClassId/scope-and-sequence',
                title: 'Scope & Sequence',
                loadComponent: () =>
                    import('@lib/frontend-school-sequence-feature-planner-sequence-editor'),
            },
            {
                path: 'classes/:schoolClassId/assignments/assessment/create',
                title: 'Add Assessment',
                loadComponent: () =>
                    import(
                        '@lib/frontend-school-assignments-feature-create-assessment-assignment-page'
                    ),
            },
            {
                path: 'classes/:schoolClassId/assignments/miscellaneous/create',
                title: 'Add Assignment',
                loadComponent: () =>
                    import(
                        '@lib/frontend-school-assignments-feature-create-miscellaneous-assignment-page'
                    ),
            },
            {
                path: 'classes/:schoolClassId/assignments/assessment/:classAssessmentAssignmentId',
                title: 'View Assessment',
                loadComponent: () =>
                    import(
                        '@lib/frontend-school-assignments-feature-assessment-assignment-details-page'
                    ),
            },
            {
                path: 'classes/:schoolClassId/assignments/assessment/:assignmentId/edit',
                title: 'Edit Assessment',
                loadComponent: () =>
                    import(
                        '@lib/frontend-school-assignments-feature-edit-assessment-assignment-page'
                    ),
            },
            {
                path: 'classes/:schoolClassId/students/:studentId',
                title: 'Student Details',
                loadComponent: () => import('@lib/frontend-school-feature-student-details-page'),
                data: {
                    edit: true,
                },
            },
            {
                path: 'classes/:schoolClassId/assignments/miscellaneous/:assignmentId/edit',
                title: 'Edit Assignment',
                loadComponent: () =>
                    import(
                        '@lib/frontend-school-assignments-feature-edit-miscellaneous-assignment-page'
                    ),
            },
            {
                path: 'dashboard',
                title: 'Dashboard',
                loadComponent: () =>
                    import('@lib/frontend-school-dashboard-feature-dashboard-page'),
            },
            {
                path: 'example-sequences',
                title: 'Suggested Scope & Sequences',
                loadComponent: () =>
                    import('@lib/frontend-school-sequence-feature-sequence-examples'),
            },
            {
                path: 'videos',
                title: 'Videos',
                loadComponent: () => import('@lib/frontend-shared-feature-media-library-list-page'),
                data: {
                    library: NamedMediaLibrary.TEACHER,
                    isSchoolApplication: true,
                },
            },
            {
                path: 'videos/:id',
                title: 'Video Details',
                loadComponent: () =>
                    import('@lib/frontend-shared-feature-media-library-detail-page'),
            },
            {
                path: 'resources',
                title: 'Resources',
                loadComponent: () =>
                    import('@lib/frontend-shared-resource-feature-browse-resources'),
            },
            {
                path: 'resources/:resourceId',
                title: 'Resource Details',
                loadComponent: () =>
                    import('@lib/frontend-shared-resource-feature-resource-details'),
            },
            {
                path: 'skills',
                title: 'Skills / Standards',
                loadComponent: () => import('@lib/frontend-shared-skill-feature-skills-page'),
            },
            {
                path: 'plan',
                title: 'My Planner',
                loadComponent: () =>
                    import('@lib/frontend-school-feature-my-planner-temporary-page'),
            },
            {
                path: 'planner',
                title: 'My Planner - DEV',
                canMatch: [myPlannerEnabled],
                loadComponent: () => import('@lib/frontend-school-feature-detailed-planner'),
            },
            ...PROGRAM_CONTENT_PAGE_ROUTES,
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'account',
        component: RegistrationWrapperComponent,
        children: [
            {
                path: 'register',
                title: 'Register',
                loadComponent: () =>
                    import('@lib/frontend-shared-authentication-feature-register-page'),
            },
            {
                path: 'confirm-registration',
                redirectTo: 'register',
                pathMatch: 'full',
            },
            {
                path: 'confirm-registration/:email',
                title: 'Confirm Registration',
                canActivate: [notLoggedIn],
                loadComponent: () =>
                    import('@lib/frontend-shared-authentication-feature-confirm-registration-page'),
            },
            {
                path: 'login',
                title: 'Login',
                canActivate: [notLoggedIn],
                loadComponent: () =>
                    import('@lib/frontend-school-authentication-feature-login-page'),
            },
            {
                path: 'onboarding',
                title: "Welcome to the EB Teacher's ELA Portal",
                canActivate: [isLoggedIn],
                loadComponent: () =>
                    import('@lib/frontend-school-accounts-feature-onboarding-page'),
            },
            {
                path: 'reset-password',
                title: 'Reset Password',
                loadComponent: () =>
                    import('@lib/frontend-shared-authentication-feature-reset-password-page'),
            },
            {
                path: 'change-password',
                redirectTo: 'reset-password',
                pathMatch: 'full',
            },
            {
                path: 'change-password/:email',
                title: 'Change Password',
                loadComponent: () =>
                    import('@lib/frontend-shared-authentication-feature-change-password-page'),
            },
        ],
    },
    {
        // Redirect unrecognized routes to the dashboard if the user is logged in,
        // otherwise redirect to the login page.
        // This can't be used in a group with the isLoggedIn guard,
        // as it will cause a redirect loop.
        path: '**',
        redirectTo: '',
    },
];
