import { Inject, Injectable } from '@angular/core';
import {
    FEATURE_ACCESS_CONFIGURATION_TOKEN,
    FeatureAccessConfiguration,
} from './provide-feature-access-configuration';
import { FeatureFlag } from '@lib/shared-interface-feature-flag';

@Injectable({
    providedIn: 'root',
})
export class FeatureAccessService {
    private disabledFeatureSet = new Set(this.configuration.disabledFeatures);

    public constructor(
        @Inject(FEATURE_ACCESS_CONFIGURATION_TOKEN)
        private readonly configuration: FeatureAccessConfiguration,
    ) {}

    public canAccessFeature(feature: FeatureFlag): boolean {
        return !this.disabledFeatureSet.has(feature);
    }
}
