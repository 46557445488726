import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'lib-registration-wrapper',
    standalone: true,
    imports: [CommonModule, IonicModule],
    templateUrl: './registration-wrapper.component.html',
    styleUrl: './registration-wrapper.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationWrapperComponent {}
