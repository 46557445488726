import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlag } from '@lib/shared-interface-feature-flag';
import { FeatureAccessService } from './feature-access.service';

@Directive({
    selector: '[libRequireFeature]',
    standalone: true,
})
export class RequireFeatureDirective implements OnInit {
    @Input({ alias: 'libRequireFeature', required: true }) public featureFlag!:
        | FeatureFlag
        | FeatureFlag[];

    public constructor(
        private readonly featureAccessService: FeatureAccessService,
        private readonly template: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
    ) {}

    public ngOnInit() {
        const featureFlags = Array.isArray(this.featureFlag)
            ? this.featureFlag
            : [this.featureFlag];
        const featureIsEnabled =
            featureFlags.length === 0 ||
            // By default, comparison is an "or".
            // We could later add an input that will cause it compare as an "and".
            featureFlags.some((flag) => this.featureAccessService.canAccessFeature(flag));

        featureIsEnabled
            ? this.viewContainer.createEmbeddedView(this.template)
            : this.viewContainer.clear();
    }
}
