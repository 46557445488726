import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { PermissionsService } from './permissions.service';
import { SchoolAccountPlan } from '@lib/shared-interface-account';

@Directive({
    selector: '[libRequirePlan]',
    standalone: true,
})
export class RequirePlanDirective implements OnInit {
    @Input({ alias: 'libRequirePlan', required: true }) public plan!: SchoolAccountPlan;

    public constructor(
        private readonly permissionsService: PermissionsService,
        private readonly template: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
        private readonly changeDetector: ChangeDetectorRef,
    ) {}

    public async ngOnInit(): Promise<void> {
        const userHasPlan = await this.permissionsService.hasPlan(this.plan);

        userHasPlan
            ? this.viewContainer.createEmbeddedView(this.template)
            : this.viewContainer.clear();
        this.changeDetector.markForCheck();
    }
}
