@if (account$ | async; as account) {
    <lib-branded-navigation #navigation [socialLinks]="socialLinks">
        <!-- Without tabindex="" this requires two tabs to actually focus the correct element -->
        <ion-button
            desktopNavigationLink
            routerDirection="root"
            routerLink="/plan"
            routerLinkActive="selected"
            shape="round"
            tabindex=""
        >
            {{ 'shared.plan.myPlanner' | translate }}
        </ion-button>
        <ion-button
            *libRequirePlan="schoolAccountPlan.FULL_ACCESS"
            desktopNavigationLink
            routerDirection="root"
            routerLink="/classes"
            routerLinkActive="selected"
            shape="round"
            tabindex=""
        >
            {{ 'shared.classes.classes' | translate }}
        </ion-button>
        <ion-button
            desktopNavigationLink
            routerDirection="root"
            routerLink="/resources"
            routerLinkActive="selected"
            shape="round"
            tabindex=""
        >
            {{ 'shared.resources.resources' | translate }}
        </ion-button>
        <ion-button
            desktopNavigationLink
            routerDirection="root"
            routerLink="/videos"
            routerLinkActive="selected"
            shape="round"
            tabindex=""
        >
            {{ 'shared.videos.videos' | translate }}
        </ion-button>

        <ion-tab-button mobileTabButton tab="plan">
            <ion-label>
                {{ 'shared.navigation.mobile.planner' | translate }}
            </ion-label>
            <ion-icon name="calendar-outline" />
        </ion-tab-button>
        <ion-tab-button [disabled]="classesDisabled()" mobileTabButton tab="classes">
            <ion-label>
                {{ 'shared.navigation.mobile.classes' | translate }}
            </ion-label>
            <ion-icon name="school-outline" />
        </ion-tab-button>
        <ion-tab-button mobileTabButton tab="dashboard">
            <picture>
                <source
                    media="(prefers-color-scheme:dark)"
                    srcset="/assets/logos/logo-icon-only-black.svg"
                />
                <img alt="Logo" class="logo-icon" src="/assets/logos/logo-icon-only-black.svg" />
            </picture>
        </ion-tab-button>
        <ion-tab-button mobileTabButton tab="resources">
            <ion-label>
                {{ 'shared.navigation.mobile.resources' | translate }}
            </ion-label>
            <ion-icon name="book-outline" />
        </ion-tab-button>
        <ion-tab-button mobileTabButton tab="videos">
            <ion-label>
                {{ 'shared.videos.videos' | translate }}
            </ion-label>
            <ion-icon src="/assets/icons/video_library.svg" />
        </ion-tab-button>

        <ion-accordion-group sideMenuContent value="programs" class="accordion-icon-quarter-turn">
            <ion-item
                *libRequireFeature="featureFlag.MY_PLANNER"
                (click)="navigation.closeWhenSmall()"
                routerLink="/planner"
                routerLinkActive="selected"
                detail="false"
            >
                <ion-label>NEW PLANNER</ion-label>
            </ion-item>
            <ion-accordion value="programs" toggleIcon="chevron-forward">
                <ion-item slot="header">
                    <ion-label> {{ 'shared.navigation.info.programs' | translate }} </ion-label>
                </ion-item>
                <ion-list (click)="navigation.closeWhenSmall()" slot="content" lines="none">
                    <ion-item
                        detail="false"
                        routerLink="reading-program"
                        routerLinkActive="selected"
                    >
                        <ion-label>
                            {{ 'school.navigation.readingProgram.readingProgram' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item
                        detail="false"
                        routerLink="writing-program"
                        routerLinkActive="selected"
                    >
                        <ion-label>
                            {{ 'school.navigation.writingProgram.writingProgram' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item
                        detail="false"
                        routerLink="grammar-program"
                        routerLinkActive="selected"
                    >
                        <ion-label>
                            {{ 'school.navigation.grammarProgram.grammarProgram' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item
                        detail="false"
                        routerLink="vocabulary-program"
                        routerLinkActive="selected"
                    >
                        <ion-label>
                            {{
                                'school.navigation.vocabularyProgram.vocabularyProgram' | translate
                            }}
                        </ion-label>
                    </ion-item>
                </ion-list>
            </ion-accordion>
            <ion-accordion value="ebEssentials" toggleIcon="chevron-forward">
                <ion-item slot="header">
                    <ion-label> EB ESSENTIALS </ion-label>
                </ion-item>
                <ion-list (click)="navigation.closeWhenSmall()" slot="content" lines="none">
                    <ion-item routerLink="/skills" routerLinkActive="selected" detail="false">
                        <ion-label>
                            {{ 'shared.skills.skillsAndStandards' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item
                        routerLink="/example-sequences"
                        routerLinkActive="selected"
                        detail="false"
                    >
                        <ion-label> Scope + Sequences </ion-label>
                    </ion-item>
                    <ion-item
                        routerLink="/calendar-of-events"
                        routerLinkActive="selected"
                        detail="false"
                    >
                        <ion-label> Calendar of Events </ion-label>
                    </ion-item>
                    <ion-item
                        href="https://www.ebteacher.com/products/communities/theebteacherscommunity/home"
                        target="_blank"
                        detail="false"
                    >
                        <ion-label> Community </ion-label>
                    </ion-item>
                </ion-list>
            </ion-accordion>
            <ion-accordion value="schools" toggleIcon="chevron-forward">
                <ion-item slot="header">
                    <ion-label> Schools </ion-label>
                </ion-item>
                <ion-list (click)="navigation.closeWhenSmall()" slot="content" lines="none">
                    <ion-item
                        *libRequirePlan="schoolAccountPlan.FULL_ACCESS"
                        detail="false"
                        routerLink="/accounts/staff"
                        routerLinkActive="selected"
                    >
                        <ion-label>
                            {{ 'school.navigation.schoolStaff' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item
                        *libRequirePlan="schoolAccountPlan.FULL_ACCESS"
                        detail="false"
                        target="_blank"
                        href="https://help.ebteacher.com/category/164-all-access-license-support"
                        routerLinkActive="selected"
                    >
                        <ion-label>
                            {{ 'school.navigation.schoolsHelp' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item *libRequirePlan="schoolAccountPlan.BASIC">
                        <ion-note class="all-access-information">
                            Want to know what the EB All-Access Suite can do for you and your
                            students? Learn more about our
                            <a target="_blank" href="https://schools.ebacademics.com/eb-all-access">
                                School Partnership Program </a
                            >!
                        </ion-note>
                    </ion-item>
                </ion-list>
            </ion-accordion>
            <ion-accordion value="support" toggleIcon="chevron-forward">
                <ion-item slot="header">
                    <ion-label>
                        {{ 'shared.navigation.info.support' | translate }}
                    </ion-label>
                </ion-item>
                <ion-list (click)="navigation.closeWhenSmall()" slot="content" lines="none">
                    <ion-item
                        detail="false"
                        target="_blank"
                        href="https://www.ebacademics.com/terms-of-use"
                        routerLinkActive="selected"
                    >
                        <ion-label> Terms of Use </ion-label>
                    </ion-item>
                    <ion-item
                        detail="false"
                        target="_blank"
                        href="https://www.ebacademics.com/privacy-policy"
                        routerLinkActive="selected"
                    >
                        <ion-label>
                            {{ 'shared.navigation.info.privacyPolicy' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item detail="false" class="help-scout-link" (click)="openHelpScout()">
                        <ion-label>
                            {{ 'shared.navigation.info.getSupport' | translate }}
                        </ion-label>
                    </ion-item>
                </ion-list>
            </ion-accordion>
        </ion-accordion-group>

        @if (accountAvatar$ | async; as avatar) {
            <!-- If ion-button is on the outside,
                then when the profile icon changes it doesn't update properly.
                keep this on the inside of the @if -->
            <ion-button accountMenuToggle>
                <ion-avatar>
                    <img [src]="avatar.source" [alt]="avatar.alternateText" />
                </ion-avatar>
            </ion-button>
        } @else {
            <ion-button accountMenuToggle>
                <ion-avatar slot="icon-only">
                    <ion-icon name="person-circle-outline" />
                </ion-avatar>
            </ion-button>
        }

        <ion-avatar accountMenuToolbarContent slot="start">
            @if (accountAvatar$ | async; as avatar) {
                <img [src]="avatar.source" [alt]="avatar.alternateText" />
            } @else {
                <ion-icon name="person-circle-outline" />
            }
        </ion-avatar>
        <ion-title accountMenuToolbarContent> {{ accountTitle$ | async }} </ion-title>

        <ion-menu-toggle accountMenuContent menu="account-menu">
            <ion-list lines="none" class="ion-no-padding">
                <ion-item detail="false" routerLink="/account/profile" routerLinkActive="selected">
                    <ion-label>
                        {{ 'shared.navigation.account.profile' | translate }}
                    </ion-label>
                    <ion-icon slot="end" name="person-circle-outline" />
                </ion-item>
                @if (isIndependentUser$ | async) {
                    <ion-item
                        detail="false"
                        routerLink="/account/billing"
                        routerLinkActive="selected"
                    >
                        <ion-label> Billing </ion-label>
                        <ion-icon slot="end" name="card-outline" />
                    </ion-item>
                }
                @if (isLoggedIn$ | async) {
                    <ion-item (click)="logOut()" button="" detail="false" lines="full">
                        <ion-label> {{ 'shared.navigation.account.logout' | translate }}</ion-label>
                        <ion-icon slot="end" name="log-out-outline" />
                    </ion-item>
                } @else {
                    <ion-item
                        button=""
                        detail="false"
                        lines="full"
                        routerLink="account/login"
                        routerLinkActive="selected"
                    >
                        <ion-label> {{ 'shared.navigation.account.login' | translate }}</ion-label>
                        <ion-icon slot="end" src="assets/icons/login.svg" />
                    </ion-item>
                }
            </ion-list>
        </ion-menu-toggle>
    </lib-branded-navigation>
}
