import { createStringEnum, EnumValues, Uuid } from '@lib/shared-interface-utility-types';
import { StorageObject, StorageObjectInput } from '@lib/shared-interface-storage-object';
import { UploadableFile } from '@lib/shared-interface-resource';

/**
 * Properties of a media library object likely to be shared in many contexts
 */
export type Media = {
    /**
     * Id assigned by the database
     * */
    id: Uuid;

    /**
     * Title of the media library object provided by the user
     */
    title: string;

    /**
     * Description of the media library object provided by the user
     */
    description?: string;

    /**
     * The storage object associated with the media library object
     */
    includedObject: StorageObject;

    /**
     * The poster storage object associated with the media library video object
     */
    includedPoster?: StorageObject;

    /**
     * The poster storage object associated with the media library video object
     */
    thumbnailPoster?: StorageObject;

    /**
     * The poster storage object associated with the media library video object
     */
    fullSizePoster?: StorageObject;

    /**
     * The VTT file storage object associated with the media library video object for closed captions
     */
    includedVttObject?: StorageObject;

    /**
     * The poster storage object associated with the media library video object
     */
    programThumbnailPoster?: StorageObject;

    /**
     * Accessibility of the media library object by teachers
     */
    accessibleByTeachers?: boolean;

    /**
     * Accessibility of the media library object by students
     */
    accessibleByStudents?: boolean;

    /**
     * Include in teacher professional development library
     */
    includeInTeacherPdLibrary?: boolean;

    /**
     * Include in student library
     */
    includeInStudentLibrary?: boolean;

    /**
     * When the account data was first created.
     */
    createdAt?: number;
    /**
     * When the account data was last updated.
     */
    updatedAt?: number;
};

export type MediaInput = Omit<
    Media,
    | 'id'
    | 'includedObject'
    | 'includedVttObject'
    | 'includedPoster'
    | 'fullSizePoster'
    | 'thumbnailPoster'
    | 'programThumbnailPoster'
    | 'createdAt'
    | 'updatedAt'
> & {
    id?: Uuid;
    includedObject?: StorageObjectInput;
    includedVttObject?: StorageObjectInput;
    includedPoster?: StorageObjectInput;
    thumbnailPoster?: StorageObjectInput;
    fullSizePoster?: StorageObjectInput;
    programThumbnailPoster?: StorageObjectInput;
};

export type MediaFormOutput = Omit<
    Media,
    | 'id'
    | 'includedObject'
    | 'includedVttObject'
    | 'includedPoster'
    | 'fullSizePoster'
    | 'thumbnailPoster'
    | 'programThumbnailPoster'
    | 'createdAt'
    | 'updatedAt'
> & {
    id?: Uuid;
    includedObject?: UploadableFile;
    includedVttObject?: UploadableFile;
    includedPoster?: UploadableFile;
    thumbnailPoster?: UploadableFile;
    fullSizePoster?: UploadableFile;
    programThumbnailPoster?: UploadableFile;
};

export type DatabaseMedia = Omit<
    Media,
    | 'includedObject'
    | 'includedVttObject'
    | 'includedPoster'
    | 'fullSizePoster'
    | 'thumbnailPoster'
    | 'programThumbnailPoster'
> & {
    includedObjectId: string;
    includedVttObjectId?: string;
    includedPosterId?: string;
    fullSizePosterId?: string;
    thumbnailPosterId?: string;
    programThumbnailPosterId?: string;
};

export const NamedMediaLibrary = createStringEnum(['TEACHER', 'STUDENT', 'OTHER']);
export type NamedMediaLibrary = EnumValues<typeof NamedMediaLibrary>;
export const NAMED_MEDIA_LIBRARY = Object.values(NamedMediaLibrary);
