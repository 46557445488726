import { Injectable } from '@angular/core';
import { AccountsService } from '@lib/frontend-shared-accounts-data-accounts-service';
import { SchoolAccountRole, SchoolAccountPlan } from '@lib/shared-interface-account';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    public constructor(private readonly accountsService: AccountsService) {}

    public async hasRoles(roles: SchoolAccountRole[]): Promise<boolean> {
        const account = await firstValueFrom(this.accountsService.userAccount$);
        if (account == undefined) {
            return false;
        }
        const userRoles = account?.additionalSchoolUserData?.roles ?? [];
        const hasRole = (role: SchoolAccountRole) => {
            return userRoles.includes(role);
        };
        return roles.every(hasRole);
    }

    public async hasPlan(plan: SchoolAccountPlan): Promise<boolean> {
        const account = await firstValueFrom(this.accountsService.userAccount$);
        if (account == undefined) {
            return false;
        }
        return account?.additionalSchoolUserData?.plan === plan;
    }
}
